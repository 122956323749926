import * as Herz from '@rushplay/herz'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as ThemeUi from 'theme-ui'

import {HtmlContent} from './html-content'

export function StaticPage() {
  const params = ReactRouterDom.useParams()
  const page = R.head(params)

  const translate = Herz.I18n.useTranslate(
    () => [`${page}.title`, `${page}.content`],
    [page]
  )

  return (
    <ThemeUi.Box
      as="section"
      sx={{
        flex: 1,
        mx: 'auto',
        maxWidth: '800px',
        width: '100%',
        px: 2,
        pb: 4,
      }}
    >
      <ThemeUi.Heading
        as="h1"
        sx={{
          textAlign: 'center',
          pb: [3, 4],
          pt: [4, 6],
          fontSize: [4, 5],
        }}
      >
        {translate(`${page}.title`)}
      </ThemeUi.Heading>
      <HtmlContent>{translate(`${page}.content`)}</HtmlContent>
    </ThemeUi.Box>
  )
}

// For @loadable/components
export default StaticPage
